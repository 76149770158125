:root {
  --display-bottom: 850px;
  --controller-scale: 0.6;
  --controller-height: 812px;
  --controller-width: 375px;
  --controller-spacing: 20px;
  --controller-top-diff: calc(((1 - var(--controller-scale)) / 2) * var(--controller-height));
  --controller-left-offset-old: calc(-0.5 * var(--controller-scale) * var(--controller-width));
  --controller-left-offset: calc(((1 - var(--controller-scale)) / 2) * var(--controller-width));
}

.container {
  position: relative;
}

.display {
  position: absolute;
  left: -180px;
  top: -100px;
  width: 1920px;
  height: 1080px;
  transform: scale(0.8);
  overflow: hidden;
}
